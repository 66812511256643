import React, { useEffect, useState } from "react"

const defaultState = {
  bookingForm: {
    open: false,
    type: null,
    id: null,
    eventId: null,
    resTime: [],
  },
  setBookingForm: () => {},
}

export const BookingForm = React.createContext(defaultState)

export default function BookingProvider({ children }) {
  const [bookingForm, setBookingForm] = useState({
    open: false,
    type: null,
    id: null,
    eventId: null,
    resTime: [],
  })
  return (
    <BookingForm.Provider
      value={{
        bookingForm,
        setBookingForm,
      }}
    >
      {children}
    </BookingForm.Provider>
  )
}
