// custom CSS styles
import React from "react"
import "./src/style.css"
import "@fontsource/inter"
// Highlighting for code blocks
import "prismjs/themes/prism.css"
import "react-datepicker/dist/react-datepicker.css"
import "react-spring-modal/styles.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "react-lazy-load-image-component/src/effects/blur.css"
import BookingProvider from "./src/context/context"

export const wrapRootElement = ({ element }) => (
  <BookingProvider>{element}</BookingProvider>
)
